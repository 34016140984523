/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { LazyCollection, Collection } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { overrideLayouts } from "../../utils";
import { getCollectionTemplate } from "../get-collection-template";
import { isLightRowsEnabled } from "../../utils";


export const HomePage = props => {
  const collection = overrideLayouts(props.config, props.data.collection, "home");
  const pbConfig = get(props, ["config", "pagebuilder-config"], {})
  const lightRows = isLightRowsEnabled(pbConfig)
  return (
    <div className="home-page">
      {/* Don't want to show it user but need it for SEO reasons🙄 */}
      <h1 style={{ display: "none" }}>{get(props.config, ["publisher-settings", "title"], props["publisher-name"])}</h1>
      {lightRows ? (
        <Collection collection={collection} collectionTemplates={getCollectionTemplate} />
      ) : (
        <LazyCollection collection={collection} collectionTemplates={getCollectionTemplate} lazyAfter={6} />
      )}
    </div>
  );
};

HomePage.propTypes = {
  config: PropTypes.shape({
    "publisher-settings": PropTypes.object,
    title: PropTypes.string
  }),
  data: PropTypes.shape({
    collection: PropTypes.object
  }),
  "publisher-name": PropTypes.string
};
